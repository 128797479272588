import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import PluginOutlet from "discourse/components/plugin-outlet";
import concatClass from "discourse/helpers/concat-class";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import { applyValueTransformer } from "discourse/lib/transformer";
import DiscourseURL from "discourse/lib/url";
import getURL from "discourse-common/lib/get-url";
import HomeLogoContents from "./home-logo-contents";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class HomeLogo extends Component {
  static #_ = (() => dt7948.g(this.prototype, "session", [service]))();
  #session = (() => (dt7948.i(this, "session"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  darkModeAvailable = (() => this.session.darkModeAvailable)();
  get href() {
    return applyValueTransformer("home-logo-href", getURL("/"));
  }
  get showMobileLogo() {
    return this.site.mobileView && this.logoResolver("mobile_logo").length > 0;
  }
  get logoUrl() {
    return this.logoResolver("logo");
  }
  get logoUrlDark() {
    return this.logoResolver("logo", {
      dark: this.darkModeAvailable
    });
  }
  get logoSmallUrl() {
    return this.logoResolver("logo_small");
  }
  get logoSmallUrlDark() {
    return this.logoResolver("logo_small", {
      dark: this.darkModeAvailable
    });
  }
  get mobileLogoUrl() {
    return this.logoResolver("mobile_logo");
  }
  get mobileLogoUrlDark() {
    return this.logoResolver("mobile_logo", {
      dark: this.darkModeAvailable
    });
  }
  get title() {
    return this.siteSettings.title;
  }
  logoResolver(name1) {
    let opts1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    // get alternative logos for browser dark dark mode switching
    if (opts1.dark) {
      return this.siteSettings[`site_${name1}_dark_url`];
    }
    // try dark logos first when color scheme is dark
    // this is independent of browser dark mode
    // hence the fallback to normal logos
    if (this.session.defaultColorSchemeIsDark) {
      return this.siteSettings[`site_${name1}_dark_url`] || this.siteSettings[`site_${name1}_url`] || "";
    }
    return this.siteSettings[`site_${name1}_url`] || "";
  }
  click(e1) {
    if (wantsNewWindow(e1)) {
      return false;
    }
    e1.preventDefault();
    DiscourseURL.routeToTag(e1.target.closest("a"));
  }
  static #_4 = (() => dt7948.n(this.prototype, "click", [action]))();
  static #_5 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <PluginOutlet @name="home-logo" @outletArgs={{hash minimized=@minimized}}>
        <div class={{concatClass (if @minimized "title--minimized") "title"}}>
          <a href={{this.href}} {{on "click" this.click}}>
            <PluginOutlet
              @name="home-logo-contents"
              @outletArgs={{hash
                logoSmallUrl=this.logoSmallUrl
                logoSmallUrlDark=this.logoSmallUrlDark
                logoUrl=this.logoUrl
                logoUrlDark=this.logoUrlDark
                minimized=@minimized
                mobileLogoUrl=this.mobileLogoUrl
                mobileLogoUrlDark=this.mobileLogoUrlDark
                showMobileLogo=this.showMobileLogo
                title=this.title
              }}
            >
              <HomeLogoContents
                @logoSmallUrl={{this.logoSmallUrl}}
                @logoSmallUrlDark={{this.logoSmallUrlDark}}
                @logoUrl={{this.logoUrl}}
                @logoUrlDark={{this.logoUrlDark}}
                @minimized={{@minimized}}
                @mobileLogoUrl={{this.mobileLogoUrl}}
                @mobileLogoUrlDark={{this.mobileLogoUrlDark}}
                @showMobileLogo={{this.showMobileLogo}}
                @title={{this.title}}
              />
            </PluginOutlet>
          </a>
        </div>
      </PluginOutlet>
    
  */
  {
    "id": "u2jSwFlH",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"home-logo\",[28,[32,1],null,[[\"minimized\"],[[30,1]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[15,0,[28,[32,2],[[52,[30,1],\"title--minimized\"],\"title\"],null]],[12],[1,\"\\n        \"],[11,3],[16,6,[30,0,[\"href\"]]],[4,[32,3],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"home-logo-contents\",[28,[32,1],null,[[\"logoSmallUrl\",\"logoSmallUrlDark\",\"logoUrl\",\"logoUrlDark\",\"minimized\",\"mobileLogoUrl\",\"mobileLogoUrlDark\",\"showMobileLogo\",\"title\"],[[30,0,[\"logoSmallUrl\"]],[30,0,[\"logoSmallUrlDark\"]],[30,0,[\"logoUrl\"]],[30,0,[\"logoUrlDark\"]],[30,1],[30,0,[\"mobileLogoUrl\"]],[30,0,[\"mobileLogoUrlDark\"]],[30,0,[\"showMobileLogo\"]],[30,0,[\"title\"]]]]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,4],null,[[\"@logoSmallUrl\",\"@logoSmallUrlDark\",\"@logoUrl\",\"@logoUrlDark\",\"@minimized\",\"@mobileLogoUrl\",\"@mobileLogoUrlDark\",\"@showMobileLogo\",\"@title\"],[[30,0,[\"logoSmallUrl\"]],[30,0,[\"logoSmallUrlDark\"]],[30,0,[\"logoUrl\"]],[30,0,[\"logoUrlDark\"]],[30,1],[30,0,[\"mobileLogoUrl\"]],[30,0,[\"mobileLogoUrlDark\"]],[30,0,[\"showMobileLogo\"]],[30,0,[\"title\"]]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@minimized\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/home-logo.js",
    "scope": () => [PluginOutlet, hash, concatClass, on, HomeLogoContents],
    "isStrictMode": true
  }), this))();
}